import React from "react"
import BackgroundImage from 'gatsby-background-image'
import {Link} from 'gatsby'

const HeroLarge = ({title, cta, image}) => {
  return (
    <BackgroundImage 
        tag="section" 
        className="hero hero--large w-full bg-blue flex flex-wrap items-center justify-center px-2 mb-16 md:mb-24" 
        fluid={image} 
    >   
        <div className="text-center text-white">
          {title && <h1 className="text-4xl md:text-5xl text-white font-bold uppercase text-center mb-6" dangerouslySetInnerHTML={{__html: title}} /> }
          {cta &&
            <Link className="btn text-white text-2xl font-bold transition bg-primary px-12 py-6 no-underline inline-block hover:shadow-lg hover:scale-105 uppercase" to={cta.link}>
              {cta.title}
            </Link>
          }
        </div>
    </BackgroundImage>
  )
}

export default HeroLarge
